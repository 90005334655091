export const environment = {
 production: false,
 userURL: 'https://qa-api.ourlane.com/userService/v1',
 staticURL: 'https://qa-api.ourlane.com/staticService/v1',
 tripURL: 'https://qa-api.ourlane.com/tripService/v1',

 carURL: 'https://qa-api.ourlane.com/carService/v1',
 notificationURL: 'https://qa-api.ourlane.com/notificationService/v1',
 tripOverviewURL: 'https://qa-api.ourlane.com/tripOverviewService/v1',
 infoService: 'https://qa-api.ourlane.com/infoService/v1',
 chatURL: 'https://qa-api.ourlane.com/chatService/v1/',
 chatDomain: 'qa-chat.ourlane.com',
OPEN_FIRE_DOMIAN: 'qa-chat.ourlane.com',
 chatService: 'wss://qa-chat.ourlane.com/ws',
 infoURL: 'https://qa-api.ourlane.com/infoService/v1',

 mqttURL: 'broker.emqx.io',
 encryptionPayKey: 'WcM2PktuqtQp9rjp',
   paymentURL: 'https://qa-api.ourlane.com/paymentService/v1',
 mqttPort: 8084,
 mqttPath: '/mqtt',

 siteKey: '6LezEt0cAAAAADrGJLNQTz0q3LPrqa6mlwIBs0G6',


 // S3 bucket details
 s3AccessKeyId: 'AKIARDLXDWGHMNUBGAIN',
 s3SecretAccessKey: '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',
 s3Region: 'ap-south-1',
 s3Bucket: 'ourlane-images',
 s3Expires: 5 * 60 * 1000,
 mapbox: {
     accessToken: 'pk.eyJ1IjoiYmxvY2tnZW1pbmkxMjMiLCJhIjoiY2xkbG5tYjB4MDE2dzN0b2w1bDc3bHZ5eCJ9.G3i52PvvTkXGq5ImwTTApQ'
     },
   pusherConfig : {
    clientKey: '7c53ae872cf1f36d1f44',
    cluster: 'eu',
    authEndpoint:'https://qa-api.ourlane.com/tripService/v1/pusher/auth',
    forceTLS: true,
  },
};
