import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { endpoints } from '../_config/url-endpoint';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChatDBServices {

  private chatListener = new Subject<any>();
  constructor(private http: HttpClient) { }

  saveChat(data: any): Observable<any> {
    return this.http.post(endpoints.chat.saveChat, data);
  }

  getAllChatTickets(): Observable<any> {
    return this.http.get(endpoints.chat.getTickets);
  }
  resolveChat(fromUser, toUser): Observable<any> {
    return this.http.put(`${endpoints.chat.getResolve}?fromUser=${fromUser}&toUser=${toUser}`, null);
  }
  getConversationByUserWithAdmin(admin, user, isResolve): Observable<any> {
    return this.http.get(`${endpoints.chat.getConversationByUserWithAdmin}?admin=${admin}&user=${user}&isResolve=${isResolve}`);
  }
  getChatByUser(openfireUserId): Observable<any> {
    return this.http.get(`${endpoints.chat.getChatByUser}?user=${openfireUserId}@${environment.chatDomain}`);
  }
  getChatByTicketId(ticketId): Observable<any> {
    return this.http.get(`${endpoints.chat.getChats}/${ticketId}`);
  }
  getUserLists(role): Observable<any> {
    const body = {
      perPage: 1000,
      pageNumber: 1,
      accountStatus: 'isActive',
      condition: { role }
    };
    return this.http.post(endpoints.analytics.userListUrl, body);
  }
  loadLastMessages(): Observable<any> {
    return this.http.get(endpoints.chat.loadLastMessages);
  }

  updateAssign(ticketId, assignId): Observable<any> {
    const data = {
      ticketId,
      assignId
    };
    return this.http.post(endpoints.chat.updateAssign, data);
  }
  getChatListener() {
    return this.chatListener.asObservable();
  }

}

