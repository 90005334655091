import { Injectable } from "@angular/core";
import { PusherService } from "../_services/pusher.service";



@Injectable()
export class PusherHandlerProvider {
    private DRIVER_UPDATE_LOCATION: string = 'client-UPDATE_DRIVER_LOCATION_'

    constructor (private pusher: PusherService) {}

    init(tripId: string) {
        const channelSub = this.pusher.init('presence-cab_service');
        channelSub.bind(this.DRIVER_UPDATE_LOCATION+tripId, data => {
            return data;
        });
    } 
}