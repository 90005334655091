import { Injectable } from '@angular/core';
import {
  HttpClient,
} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import Pusher from 'pusher-js';
@Injectable({
  providedIn: 'root'
})
export class PusherService {
  pusher: any;
  // channel: any;


  constructor() {
    const authorization = localStorage.getItem('access_token');
    this.pusher = new Pusher(environment.pusherConfig.clientKey, {
      cluster: environment.pusherConfig.cluster,
      forceTLS: environment.pusherConfig.forceTLS,
      authEndpoint: environment.pusherConfig.authEndpoint,
      auth: {
        headers: {
          'x-access-token':  authorization,
        },
      },
    });
  }
  // authorizePusher() {
  //   const authorization = localStorage.getItem('access_token');
  //   return new Pusher(environment.pusherConfig.clientKey, {
  //     cluster: environment.pusherConfig.cluster,
  //     forceTLS: environment.pusherConfig.forceTLS,
  //     authEndpoint: environment.pusherConfig.authEndpoint,
  //     auth: {
  //       headers: {
  //         Authorization: "Bearer " + authorization,
  //       },
  //     },
  //   });
    
  // }

  public init(channel: string) {
    return this.pusher.subscribe(channel);
  }
  // constructor(private http: HttpClient) { 
  //   const authorization = localStorage.getItem('access_token');
  //   this.pusher = new Pusher(environment.pusherConfig.clientKey, {
  //     cluster: environment.pusherConfig.cluster,
  //     forceTLS: environment.pusherConfig.forceTLS,
  //     authEndpoint: environment.pusherConfig.authEndpoint,
  //     auth: {
  //       headers: {
  //         Authorization: "Bearer " + authorization,
  //       },
  //     },
  //   });
  //   this.channel = this.pusher.subscribe('presence-cab_service');

  // }
  // like( num_likes ) {
  //   this.http.post('http://localhost:3120/update', {'likes': num_likes})
  //   .subscribe(data => {});
  // }
}
