export const routerLinks = {
    allBookings: {
        OnGoing: '/all-bookings/on-going-trips',
        CompletedCancel: '/all-bookings/completed-cancel-trips',
        ScheduleCreated: '/all-bookings/scheduled-created-trips',
        cancelTrip: '/all-bookings/cancel-trips',
        rejectTrip: '/all-bookings/reject-trips'
    },
    profile: {
        profileHome: '/home',
    },
    administration: {
        administrationHome: '/administration',
    },
    cars: {
        carHome: '/car',
    },
    customers: {
        customersHome: '/customers'
    },
    analytics: {
        analyticsHome: '/analytics'
    },
    notification: {
        notificationHome: '/notification'
    },
    accountsetting: {
        accountsettingHome: '/accountsetting'
    },
    promoCode: {
        promoCodeHome: '/promocode'
    },
    reasonOfCancellation: {
        reasonOfCancellation: '/reason-of-cancellation'
    },
    promotions: {
        promotions: '/promotions'
    }
};
