import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GlobalServices {
  contentWrap: BehaviorSubject<string> = new BehaviorSubject('15.6rem');
  getContentAsOBS() {
    return this.contentWrap.asObservable();
  }
  updateValue(value: string) {
    this.contentWrap.next(value);
  }
  public items = [];
  sumItems(items) {
    return items.reduce((a, c) => {
      return a + c;
    }, 0);
  }

  percentage(value1, value2) {
    return ((value1 - value2) / value1) * 100;
  }
}
